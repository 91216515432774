import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface AddUserDashboardResponse extends AxiosResponse {
  data: {
    isFirstLogin: boolean
    message: string
  }
}

export interface DeleteUserDashboardRequest
  extends Partial<AxiosRequestConfig> {
  params: {
    userId: number
  }
}

export function deleteUserDashboardsApi(
  { params, ...config }: DeleteUserDashboardRequest,
  axiosInstance: AxiosInstance,
): Promise<AddUserDashboardResponse> {
  return axiosInstance({
    params,
    method: "delete",
    url: `user/rbo/delete`,
    ...config,
  }) as Promise<AddUserDashboardResponse>
}
