import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"

export interface PaymentChargeLogArray {
  wmChargeId: number
  bookingId: number
  bookingNo: string
  chargeAmount: number
  currency: string
  groupId: string | null
  isActive: boolean
  isCapture: boolean
  isChargeSuccessful: boolean
  isSplitBookingCharge: boolean
  logCharacter: null | string
  paymentDate: string | null
  status: string
  stripeChargeId: null | string
  stripeCustomerId: string
  chargeCaptureAmount: number
  refundAmount: number
  stripeIntentId: string
  paymentType: string
  travellerId: number
  modifiedId: null | string | number
  isIgnore?: boolean
}

export interface PaymentTransferLogArray {
  transactionId: number
  bookingNo: string
  currency: string
  isActive: boolean
  isTransactionReverse: boolean
  isTransactionSucessfull: boolean
  logCharacter: null | string
  paymentDate: null | string
  status: string
  pmcId: string
  stripeTranferId: null | string
  transferAmount: number
  transferSendDate: string
  transferType: string
  transferReversedAmount: number
  note: null | string
  reason: null | string
  modifiedId: null | string | number
  length?: number
  isCancelStatus?: boolean
}

export interface StagedPaymentType {
  id: number
  travelerPaymentAmount: number
  refundAmount: number
  paymentChargeId: number
  paymentMethodId: string | null
  bookingNumber: string
  paymentMode: string | null
  paidDate: string | null
  paymentReceiptUrl: string | null
  paymentType: string
  deducted: boolean
}

export interface GuestInfoListType {
  firstName: string
  lastName: string
  emailId: string
}

export interface PaymentBookingType {
  chargeActivityLogs: PaymentChargeLogArray[]
  transferActivityLogs: PaymentTransferLogArray[]
  stagedPaymentTransactions: StagedPaymentType[]
  bookingNumber: string
  bookingStatus: string
  finalPaymentDueDate: string
  policyNumber: number
  riskRating: number
  currentRiskRatingForIdVerification: number
  isUserIdentityVerified: boolean
  isIdVerificationByPass: boolean
  informationalTextOptIn: boolean
  marketingTextOptIn: boolean
  guestInfo: GuestInfoListType[]
  stagedPaymentConfig: {
    firstPayment: number
    secondPayment: number
  } | null
}

interface GetPaymentBookingDetailResponseData extends AxiosResponse {
  data: PaymentBookingType
}

interface GetPaymentBookingDetailResponse extends AxiosResponse {
  data: GetPaymentBookingDetailResponseData
}

export interface GetPaymentBookingDetailRequest
  extends Partial<AxiosRequestConfig> {
  bookingNumber: string
}

export function getPaymentBookingDetailApi(
  { bookingNumber, ...config }: GetPaymentBookingDetailRequest,
  axiosInstance: AxiosInstance,
): Promise<GetPaymentBookingDetailResponse> {
  return axiosInstance({
    method: "get",
    url: `admin/booking/info?bookingNumber=${bookingNumber}`,
    ...config,
  })
}
